<template>
  <div>
    <div id="login-wrapper">
      <b-container class="login page-wrapper bg-light pb-5">
        <b-row class="main-content-wrapper pt-0" no-gutters>
          <b-col cols="12" md="5" class="pl-5 pr-5 pr-md-0 mr-5">
              <ul class="d-md-flex flex-md-column justify-content-md-end align-items-md-end mb-md-5 text-uppercase">
                <li @click="$router.push({ name: 'login'})" class="elem-active">{{ $t('login.label.login_with_email') }}</li>
                <li @click="$router.push({ name: 'forgotPassword'})">{{ $t('login.label.forgot_password') }}</li>
                <!--<li @click="$router.push({ name: 'loginWithBank'})">{{ $t('login.label.login_width_mobile_bankid') }}</li>-->
              </ul>
          </b-col>

          <b-col cols="12" md="6" class="pl-5 pr-5 pr-md-0">
            <login-by-email ref="LoginByEmail"></login-by-email>
          </b-col>

        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LoginByEmail from '../components/LoginByEmail'
import { SET_SETTINGS_ACTIVE_LANG_ID } from '@/store/actions/settings'

export default {
  name: 'login',
  mounted () {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('user', ['isAuthenticated'])
  },
  created () {
    const queryParams = this.$route.query
    const validLangParams = ['en', 'sv', 'da']
    if (Object.keys(queryParams).length > 0) {
      const langParam = Object.prototype.hasOwnProperty.call(queryParams, 'lang') && validLangParams.indexOf(queryParams.lang) > -1 ? queryParams.lang : null
      if (langParam) {
        this.$store.dispatch(SET_SETTINGS_ACTIVE_LANG_ID, langParam)
      }
    }
  },
  components: {
    LoginByEmail
  }
}
</script>

<style lang="scss" scoped>
@import "../style.scss";
</style>
