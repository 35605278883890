<template>
  <div class="loginByEmailContainer" >
    <b-form @submit="loginByEmail" class="bg-white text-left loginForm">

      <b-alert show variant="danger" v-if="!this.credentialsOk">
        <strong>{{ $t(this.translationPath + 'errors.authentication_failed')}}</strong><br/>
        <i18n :path="invalidLoginErrorMsg">
          <b-link slot="forgot_password" href="#" @click="changeToForgetPasswordForm">{{ $t(translationPath + 'errors.forgot_password')}}</b-link>
          <b-link slot="contact_us" href="#" @click="onHelpClick">{{ $t(translationPath + 'errors.contact_us')}}</b-link>
        </i18n>
      </b-alert>

      <b-form-group
        class="mb-4"
        :label="$t('login.label.email')"
        label-for="emailInput"
        :invalid-feedback="invalidEmailFeedback"
        :state="!$v.email.$error">

          <b-form-input
            type="email"
            v-model="email"
            :placeholder="$t('login.placeholder.email')"
            :state="$v.email.$dirty ? !$v.email.$error : null"
            @input="onEmailInput"
            >
          </b-form-input>
      </b-form-group>

      <b-form-group
        class="passwordInputGroup mb-4"
        :invalid-feedback="invalidPasswordFeedback"
        :state="!$v.password.$error">
        <template slot="label" tabindex="-1">
          {{ $t('login.label.password') }} <a href="#" @click.prevent="changeToForgetPasswordForm" class="text-gray" tabindex="-1"><small tabindex="-1">({{ $t('login.label.forgot_password') }})</small></a>
        </template>
        <b-form-input
          class="passwordInput"
          type="password"
          v-model="password"
          :placeholder="$t('login.placeholder.password')"
          :state="$v.password.$dirty ? !$v.password.$error : null"
          @input="onPasswordInput">
        </b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" class="w-100 text-uppercase">{{ $t('login.label.login') }}</b-button>
    </b-form>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
// import { SET_SETTINGS_ACTIVE_LANG_ID } from '@/store/actions/settings'
// import axios from 'axios'

const validCredentials = getter => function (value) {
  return this.credentialsOk
}

export default {
  name: 'LoginByEmail',
  data () {
    return {
      email: null,
      password: null,
      credentialsOk: true,
      invalidLoginErrorMsg: null,
      translationPath: 'login.'
    }
  },
  validations: {
    email: {
      required,
      email,
      credentialsOK: validCredentials()
    },
    password: {
      required,
      minLength: minLength(3),
      credentialsOK: validCredentials()
    },
    form: ['email', 'password']
  },
  methods: {
    ...mapActions('user', ['login', 'fetchData']),

    async loginByEmail (event) {
      event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.email.required && this.$v.email.email && this.$v.password.required) {
        const loginParamArray = { email: this.email.toLowerCase(), password: this.password }

        this.login(loginParamArray)
          .then(responseLogin => {
            this.$router.push('/dashboard')
          })
          .catch(error => {
            if (!error.response) {
              console.error(error)
              return
            }
            this.invalidLoginErrorMsg = this.translationPath + 'errors.invalid_credentials'

            this.credentialsOk = false
          })
      }
    },
    onHelpClick (e) {
      e.preventDefault()
      this.$zendesk.toggle()
    },
    changeToForgetPasswordForm () {
      this.$router.push({
        name: 'forgotPassword'
      })
      // this.$emit('show-forget-password-form')
    },
    onEmailInput () {
      this.credentialsOk = true
      this.$v.email.$touch()
    },
    onPasswordInput () {
      this.credentialsOk = true
      this.$v.password.$touch()
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'isAuthenticated']),
    invalidEmailFeedback () {
      if (this.$v.email.required === false) { return this.$i18n.t(this.translationPath + 'errors.require_email') }
      if (this.$v.email.email === false) { return this.$i18n.t(this.translationPath + 'errors.require_valid_email') }
      if (this.$v.email.creadentialsOK === false) { return this.$i18n.t(this.translationPath + 'errors.invalid_credentials_email') }
      return ''
    },
    invalidPasswordFeedback () {
      if (this.$v.password.required === false) { return this.$i18n.t(this.translationPath + 'errors.require_password') }
      if (this.$v.password.minLength === false) { return this.$i18n.t(this.translationPath + 'errors.require_password') }
      if (this.$v.password.credentialsOK === false) { return this.$i18n.t(this.translationPath + 'errors.invalid_credentials_password') }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./../style.scss";
  .loginByEmailContainer{
    .loginForm {
      border: 3px solid #ececec;
      border-radius: 30px;
    }
  }
</style>
